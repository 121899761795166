#search-bar-component {
  display: flex;
  // order: 2;
  //   flex: 2 1 auto;
  margin: 5px 12px;
  max-width: 400px;
  z-index: 1;
  .campo {
    border: var(--header-text-title);
    position: relative;
    border-radius: 8px;
    padding: 8px;
    height: 45px;
    box-sizing: border-box;
    display: flex;
    flex: 2 1 0%;
    background-color: rgb(255, 255, 255);
    z-index: 20;

    svg {
      width: 20px;
      order: 2;
      cursor: pointer;
    }
    input {
      order: 1;
      border: none;
      width: 100%;
      padding: 0px 8px;
      font-size: 16px;
      outline: none;
      box-sizing: border-box;
      color: var(--azul-opacidade);
    }
  }
}
