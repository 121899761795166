.data {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-weight: 500;
    align-items: center;
    width: 27%;
    .dia{
      color: var(--primaria);
      font-size: 31px;
      @media screen and (min-width: 600px) {
          height: 30px;
          line-height: 30px;
      }
  }
    .mes{
      text-transform: uppercase;
      color: var(--azul);
      font-size: 14px;
    }
    .ano{
      color: #999;
      font-size: .72em;
      padding-bottom: 5px;
      font-weight: 400;
      @media screen and (max-width:600px) {
          padding-bottom: 7px;
      }
    }
    &.meses_diferentes{
      width: 27%;
    }
    &.multiplos{
        :first-child{
          text-align: left;
        }
        :first-child::after{
          content:"";
          display: inline-block;
          height: 0.6em;
          vertical-align: bottom;
          width: 30%;
          margin-right: -100%;
          margin-left: 7px;
          border-top: 1px solid var(--cinza);
        }
        :nth-child(2){
          text-align: right;
        }
        :nth-child(2)::before{
          content:"";
          display: inline-block;
          height: 0.6em;
          vertical-align: bottom;
          width: 30%;
          margin-right: 8%;
          margin-left: 0px;
          border-top: 1px solid var(--cinza);
        }
               
        .dia{
          font-size: 20px;
          margin: 0 17px;
          @media screen and (max-width: 650px) {
            margin: 0 2px;
          }
        }
    }
}
  

//MODELOS DAS DATAS NOS CARDS
.mesmo_mes_e_ano{
  //para pt-BR
  &.pt-BR{
    :first-child{
      text-align: left;
      @media screen and (max-width: 650px) {
        text-align: center;
      }
    }
    :first-child::after{
      content: '';
      flex: 1 1 auto; 
      border-bottom: solid 1px var(--cinza);
    }
    :nth-child(2){
      text-align: right;
      width: 60%;
      @media screen and (max-width: 600px) {
        width: 85%;
      }
    }
    :nth-child(2)::before{
      content: '';
      flex: 1 1 auto; 
      border-bottom: solid 1px var(--cinza);
    }
  }

  &.en, &.es {
    :nth-child(2){
      text-align: left;

      @media screen and (max-width: 650px) {
        text-align: center;
      }
    }

    :nth-child(2)::after{
      content: '';
      flex: 1 1 auto; 
      border-bottom: solid 1px var(--cinza);
    }

    :nth-child(3){
      text-align: right;
      width: 60%;

      @media screen and (max-width: 600px) {
        width: 85%;
      }
    }

    :nth-child(3)::before{
      content: '';
      flex: 1 1 auto; 
      border-bottom: solid 1px var(--cinza);
    }
  }
         
  .dia{
      font-size: 20px;
      width: 60%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      gap: 3px;
      align-items: center;
      margin-top: -5px;
      @media screen and (max-width: 650px) {
          margin: 0 2px;
          width: 87%;
      }
  }

  @media screen and (max-width:600px) {
      .ano{
          padding-top: 2px;
      }
  }
}

.mesmo_ano{
  display: grid;
  grid-template-areas: 		
  "data1 data2"
  "mes1 mes2" 
  "ano ano"
  ;
  width: 33%;
  padding-left: 3px;
  .dia{
      font-size: 20px;
  }
  :first-child{
      grid-area: data1;
      border-right: 1px solid var(--cinza);
      padding-right: 1px;
      @media screen and (max-width: 650px) {
          border-right: none;
          padding-right: 0;
      }
  }
  :nth-child(2){
      grid-area: mes1;
      border-right: 1px solid var(--cinza);
      padding-right: 1px;
      @media screen and (max-width: 650px) {
          border-right: none;
          padding-right: 0;
          border-bottom: 1px solid var(--cinza);
      }
  }
  :nth-child(3n){
      grid-area: data2;
  }
  :nth-child(4n){
      grid-area: mes2;
  }
  .ano{
      grid-area: ano;
  }
  @media screen and (max-width:600px) {
      display: flex;
      padding-left: 0;
      width: 29%;
      .ano{
          padding-top: 2px;
          font-size: 0.9em;
      }
  }
}

.tudo_diferente{
  display: grid;
  grid-template-areas: 		
  "data1 data2"
  "mes1 mes2" 
  "ano1 ano2"
  ;
  width: 33%;
  padding-left: 3px;
  .dia{
      font-size: 20px;
  }
  :first-child{
      grid-area: data1;
      border-right: 1px solid var(--cinza);
      padding-right: 1px;
      @media screen and (max-width: 650px) {
          border-right: none;
          padding-right: 0;
      }
  }
  :nth-child(2){
      grid-area: mes1;
      border-right: 1px solid var(--cinza);
      padding-right: 1px;
      @media screen and (max-width: 650px) {
          border-right: none;
          padding-right: 0;
      }
  }
  :nth-child(3n){
      grid-area: ano1;
  }
  :nth-child(4n){
      grid-area: data2;
  }
  :nth-child(5n){
      grid-area: mes2;
  }
  :nth-child(6n){
      grid-area: ano2;
  }
  @media screen and (max-width:600px) {
      display: flex;
      padding-left: 0;
      width: 29%;
      :nth-child(3n){
          border-bottom: 1px solid var(--cinza);
      }
      :last-child{
          border-bottom: none;
      }
      .dia{
          font-size: 23px;
      }
      .ano{
          font-size: 12px;
      }
  }

}

.data-ingresso{
  .separator{
          height: 1px;
          border-width: 0;
          background: rgba(76, 76, 76, 0.33);
          margin-top: 2px;
          margin-bottom: 2px;
          width: 32px;
  }
}