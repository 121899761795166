#location-indicator {
  .localizacao {
    display: flex;
    order: 3;
    flex: 1 0 auto;
    justify-content: center;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
      color: var(--primaria);
    }
    a {
      display: flex;
      align-items: center;
      gap: 6px;
    }
    p {
      color: var(--header-text-default);
      letter-spacing: 0px;
      @media screen and (max-width: 600px) {
        color: var(--primaria);
        font-weight: 500;
      }
    }
    .alerta {
      position: absolute;
      width: 200px;
      background-color: white;
      border: 1px solid var(--color-border);
      box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      top: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      .ballon {
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;
        padding: 12px 0 14px 0;
        gap: 3.5px;
        p {
          color: var(--azul);
          letter-spacing: -0.01em;
        }
        .cidade {
          font-weight: 600;
          font-size: 21px;
        }
        .continuar {
          border-radius: 10px;
          color: var(--white);
          background-color: var(--botao-azul);
          font-weight: 600;
          padding: 8px 21px;
          margin-top: 6px;
        }
        .trocar-cidade {
          color: var(--cinza-forte);
          svg {
            color: var(--primaria);
          }
        }
      }

      &::after {
        content: "";
        height: 20px;
        width: 20px;
        background: var(--white);
        transform: rotate(45deg);
        position: absolute;
        left: 46%;
        top: -11px;
        border: 1px solid var(--color-border);
        border-color: var(--color-border) transparent transparent
          var(--color-border);
      }
    }
  }
}

//MOBILE
@media screen and (max-width: 600px) {
  #location-indicator {
    .search-mobile {
      position: fixed;
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      top: 70px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
      padding: 15px 0 13px 0;
      align-items: center;
      z-index: 3;
      background-color: var(--white);
      .localizacao {
        display: flex;
        margin-left: 20px;
        gap: 10px;
        color: var(--primaria);
        font-weight: 600;
        svg {
          width: 20px;
          color: var(--primaria);
          height: 20px;
        }
      }
      .busca {
        margin-right: 20px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

//DESKTOP
@media screen and (min-width: 600px) {
  #location-indicator {
    .search-mobile {
      display: none;
    }
  }
}
