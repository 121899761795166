.nenhum-evento{
  text-align: center;
  max-width: 600px;
  padding: 34px 0px;
  margin: 0 auto;
  color: #3f3f3f;
  @media screen and (max-width: 600px) {
    padding: 34px 4px;
  }
  svg{
    color: var(--primaria)
  }
  img{
    width: 35%;
  }
  h3{
    font-size: 1.5em;
  }
  p{
    font-size: .9em;
    padding: 2px 0px;
  }
  a{
    margin-top: 10px;
    border: 1px solid #CCCCCC;
    padding: 20px 50px;
    color: #7c7c7c;
    text-transform: uppercase;
    display: inline-flex;
    border-radius: 5px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.10));
    font-size: .9em;
  }
}