//modal de localização atual
#modal-localizacao {
    // height: 100%;
    // width: 100%;
    // display: none;
    // position: fixed;
    // z-index: 999;
    // top: 0;
    // left: 0;
    // background-color: rgba(0, 0, 0, 0.65 );
  
    .modal {
      margin: 0 auto;
      // width: 560px;
      background-color: white;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      border-radius: 5px;
      position: relative;
      top: 50%;
      // transform: translateY(-50%);
      .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .titulo{
          display: flex;
          flex-direction: column;
          :first-child{
            color: var(--link);
            text-transform: uppercase;
            font-weight: 600;
            font-size: 26px;
          }
          :last-child{
            color: var(--azul-opacidade);
            font-size: 15px;
          }
        }
        .fechar{
          cursor: pointer;
          svg{
            width: 19px;
            height: 19px;
          }
        }
      }
      // .cidades{
      //   border: thin solid var(--cinza-forte);
      //   border-radius: 8px;
      //   .scrollbar{
      //     overflow-y: overlay;
      //     max-height: 200px;
      //     width: 100%;
      //   }
      //   ::-webkit-scrollbar {
      //     background: transparent;
      //     width: 5px;
      //   }
      //   ::-webkit-scrollbar-thumb{
      //     background-color: lightgray;
      //     border-radius: 10px;
      //   }
      //   ::-webkit-scrollbar-corner{
      //     border-radius: 10px;
      //   }
  
      //   a{
      //     border-bottom: 1px solid var(--cinza-fraco);
  
      //   }
      //   .buscar{
      //     display: flex;
      //     padding: 15px 11px;
      //     border-bottom: 1px solid var(--cinza-fraco);
      //     input{
      //       width: 100%;
      //       border: none;
      //       padding: 0 8px;
      //       outline: none;
      //       font-size: 17px;
      //       color: var(--azul-opacidade);
      //     }
      //     svg{
      //       width: 20px;
      //       height: 20px;
      //     }
      //   }
  
      //   .localizacao{
      //     display: flex;
      //     gap: 10px;
      //     padding: 13px 11px;
      //     cursor: pointer;
      //     align-items: center;
      //     svg{
      //       width: 18px;
      //       height: 18px;
      //     }
      //     .texto{
      //       display: flex;
      //       flex-direction: column;
      //       :first-child{
      //         color: var(--azul);
      //         font-weight: bold;
      //         font-size: 16px;
      //       }
      //       :last-child{
      //         color: var(--azul-opacidade);
      //         font-size: 13px;
      //       }
      //     }
      //   }
  
      //   .local{
      //     cursor: pointer;
      //     display: flex;
      //     padding: 12px 11px;
      //     gap: 10px;
      //     color: var(--azul);
      //     svg{
      //       width: 20px;
      //       height: 20px;
      //     }
      //   }
      // }
      .select{
        display: flex;
        flex-direction: column;
        gap: 5px;
        label{
          font-weight: bold;
          color: var(--texto);
        }
        select{
          display: block;
          width: 100%;
          height: 34px;
          padding: 6px 12px;
          font-size: 14px;
          line-height: 1.42857143;
          color: #555;
          background-color: #fff;
          background-image: none;
          border: 1px solid #ccc;
          border-radius: 4px;
          -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
          box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
          -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
          -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
          transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        }
      }
      button{
        background-color: var(--primaria);
        border: none;
        color: #FFFFFF;
        border-radius: 4px;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.5px;
        padding: 15px 0px;
        cursor: pointer;
      }
    }
  }
