.card-cookies{
  align-items: center;
  background-color: #fff;
  z-index: 9999999;
  border-radius: 20px;
  box-shadow: 0 0.4px 3.6px rgba(0, 0, 0, 0.004),
  0 1px 8.5px rgba(0, 0, 0, 0.01), 0 1.9px 15.7px rgba(0, 0, 0, 0.019),
  0 3.4px 28.2px rgba(0, 0, 0, 0.03), 0 6.3px 54.4px rgba(0, 0, 0, 0.047),
  0 15px 137px rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  position: relative;
  width: 300px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  padding: 20px;
  color: #4F4F4F;
  @media screen and (max-width: 600px) {
    width: 85%;
    margin: 0 auto;
  }
  
  #default-cookies{
    display: block;
  }
  #form-cookies{
    display: none;
  }

  .title{
    font-weight: 600;
    font-size: 19px;
  }
  .info{
    font-size: 12.5px;
    letter-spacing: 0.01em;
    display: flex;
    flex-direction: column;
    gap: 1.5px;
    margin: 4px 0px 12px 0px;
  }
  .link{
    color: var(--primaria);
    text-decoration: underline;
    display: inline-block;
  }
  .buttons{
    display: flex;
    width: 100%;
    gap: 10px;

    a{
      align-items: center;
      background: #edf1f7;
      border-radius: 10px;
      cursor: pointer;
      display: flex;
      height: 45px;
      justify-content: center;
      text-decoration: none;
      width: 100%;
      line-height: 17px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.25px;

      i{
        font-size: 17px;
      }

      svg{
        width: 18px;
        height: 18px;
      }

      &:first-child{
        background-color: white;
        border: 2px solid var(--primaria);
        color: var(--primaria);
        gap: 5px;
      }

      &:last-child{
        background-color: var(--primaria);
        border-color: var(--primaria);
        color: white;

      }
    }
  }
  .options{
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .footer-text{
    font-size: 14px;
  }
  .description-radio{
    font-size: 12.5px;
    grid-area: "descricao";
    font-weight: 300;
  }
  .title-radio{
    grid-area: "titulo";
    font-weight: 500;
    font-size: 16px;
  }
  label{
    display: grid;
    column-gap: 10px;
    display: grid;
    grid-template-areas: 
    "input titulo"
    "input descricao"
    ;
    cursor: pointer;
  }
  .saveConfig{
    background-color: var(--primaria);
    cursor: pointer;
    border-color: var(--primaria);
    color: white;
    font-weight: 500;
    letter-spacing: 0.25px;
    height: 45px;
    border: none;
    border-radius: 10px;
  }
}
.option-input {
  grid-area: input;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  margin: -10px 0 0 !important;
  top: 13.33333px;
  border-radius: 200px;
  right: 0;
  bottom: 0;
  left: 0;
  height: 35px;
  width: 35px;
  -webkit-transition: all 0.15s ease-out 0s;
  -moz-transition: all 0.15s ease-out 0s;
  transition: all 0.15s ease-out 0s;
  background: #cbd1d8;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  position: relative;
  z-index: 1000;
}
.option-input:hover {
  background: #9faab7;
  border: transparent;
}
.option-input:checked {
  background: #43d956;
  border: transparent;

}
.option-input:checked::before {
  height: 20px;
  width: 20px;
  left: 8px;
  top: 1px;
  position: absolute;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' fill='%23FFFFFF'%3E%3C!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --%3E%3Cpath d='M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z'/%3E%3C/svg%3E");
  display: inline-block;
  font-size: 26.66667px;
  text-align: center;
  line-height: 40px;
  border: transparent;

}
.option-input:checked::after {
  background: #43d956;
  content: '';
  display: block;
  position: relative;
  z-index: 100;
  border: transparent;

}
.option-input.radio {
  border-radius: 50%;
}
.option-input.radio::after {
  border-radius: 50%;
}

input[type="radio"]:focus{
  outline: none;
}
 

